import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'types/modal';
import endpoints from 'constants/endpoints';
import routes from 'constants/routes';
import useApi from 'hooks/useApi';
import { Card, Button } from 'components';
import { Refresh } from 'iconsax-react';
import { onBoardingCompleted } from 'store/actions/user';

const I9Verification = () => {
  const api = useApi();
  const apiReset = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modal = useModal();
  const [url, setUrl] = useState();

  useEffect(() => {
    api.fetchData(endpoints.GET_I9.get());
  }, []);

  useEffect(() => {
    if (api.response) {
      if (api.response.completed) {
        dispatch(onBoardingCompleted());
        navigate(routes.HOME);
      }
    }
  }, [api.response, dispatch, navigate]);

  useEffect(() => {
    if (api.response) {
      setUrl(api.response.url);
    }
  }, [api.response]);

  useEffect(() => {
    if (apiReset.response) {
      setUrl(apiReset.response.url);
    }
  }, [apiReset.response]);

  const handleStartOver = async () => {
    modal.open(
      ModalsList.CONFIRM,
      async (flag: boolean) => {
        if (flag) {
          await apiReset.fetchData(endpoints.RESET_I9.get());
        }
      },
      {
        title: 'Are you sure you wish to continue?',
        message: 'If you reset your I9 form, all current progress will be lost.'
      }
    );
  };

  return (
    <div className="i9-Verification">
      <Card title="I-9 Verification">
        <Card.Item>
          {api.isLoading || apiReset.isLoading ? (
            <>Loading...</>
          ) : (
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                <Button text="Start Over" variant="tertiary" icon={<Refresh />} onClick={handleStartOver} />
              </div>
              <iframe id="payment-iframe" title="Payment" src={url} height={'840px'} />
            </>
          )}
        </Card.Item>
      </Card>
    </div>
  );
};

export default I9Verification;
