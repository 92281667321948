import React from 'react';
import classnames from 'classnames';
import { Chainlink } from 'iconsax-react';
import useForwardedRef from 'hooks/useForwardedRef';
import useRipple from 'hooks/useRipple';

type Props = {
  disabled?: boolean;
  className?: string;
  text?: string;
  icon?: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'alternative';
  contrast?: boolean;
  transparent?: boolean;
  capitalize?: boolean;
  selected?: boolean;
  loading?: boolean;
  small?: boolean;
  tiny?: boolean;
  iconOnRight?: boolean;
  fake?: boolean;
  fullWidth?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const Button = React.forwardRef(
  (
    {
      text,
      icon,
      onClick = () => {},
      variant = 'primary',
      disabled = false,
      contrast = false,
      selected = false,
      loading = false,
      small = false,
      tiny = false,
      transparent = false,
      capitalize = false,
      fake = false,
      iconOnRight = false,
      fullWidth = false
    }: Props,
    ref
  ) => {
    const safeRef = useForwardedRef<HTMLButtonElement>(ref);
    const Tag: any = fake ? 'div' : 'button';
    useRipple(safeRef);

    return (
      <Tag
        ref={safeRef as React.RefObject<HTMLButtonElement>}
        onClick={onClick}
        className={classnames(
          'button',
          'body-2',
          'strong',
          variant,
          { 'full-width': fullWidth },
          { selected },
          { transparent },
          { capitalize },
          { small },
          { tiny },
          { 'icon-only': !!icon && !text },
          { contrast }
        )}
        disabled={disabled || loading}
      >
        {loading ? <Chainlink className="loading" /> : !!icon && !iconOnRight ? icon : null}
        {!!text && text}
        {!!icon && !!iconOnRight && icon}
      </Tag>
    );
  }
);

export default Button;
