import React, { useRef, useCallback, useState } from 'react';
import { useDropzone, DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { Import, PlayCircle } from 'iconsax-react';
import { ModalsList } from 'types/modal';
import { Capacitor } from '@capacitor/core';
import { DropFileProps } from 'types/dynamicInputs';
import { FilePreview } from 'components';
import { useModal } from 'hooks/useModal';
import { checkVideoDuration } from 'utils/data';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { VIDEO_DURATION } from 'constants/video';
import { disableApplicantForm } from 'helpers/applicant';
import classNames from 'classnames';

const DropVideo = ({ value, onChange, schema, setSchema, disabled }: DropFileProps) => {
  const modal = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const api = useApi();
  // const apiS3 = useApi();

  const isWebPlatform = Capacitor.getPlatform() === 'web';
  const fileRecordVideoRef = useRef<HTMLInputElement>(null);
  const selectVideoRef = useRef<HTMLInputElement>(null);
  const ignoreClick = useRef(false);

  const uploadVideoToS3 = async (file: File) => {
    const signedUrl = await api.fetchData(endpoints.GET_VIDEO_SIGNED_URL.get(), {
      fileName: file.name
    });

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', e => {
      if (e.lengthComputable) {
        const percentComplete = (e.loaded / e.total) * 100;
        console.log(percentComplete);
        setUploadProgress(percentComplete);
      }
    });

    xhr.addEventListener('load', () => {
      setUploadProgress(0);
    });

    xhr.open('PUT', signedUrl.url);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);

    await new Promise((resolve, reject) => {
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error('Upload failed'));
        }
      };
      xhr.onerror = () => reject(new Error('Upload failed'));
    });

    return signedUrl.filePath;
  };

  // const uploadVideoToS3 = async (file: File) => {
  //   const signedUrl = await api.fetchData(endpoints.GET_VIDEO_SIGNED_URL.get(), {
  //     fileName: file.name
  //   });
  //   await apiS3.uploadFileToS3(file, signedUrl.extension, signedUrl.url);

  //   return signedUrl.filePath;
  // };

  const handleUpload = async (file: File) => {
    setIsLoading(true);
    disableApplicantForm({ schema, setSchema });
    const filePath = await uploadVideoToS3(file);
    onChange({
      fileName: file.name,
      fileSize: file.size,
      path: filePath
    });
    setIsLoading(false);
  };

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      handleUpload(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'video/mp4': ['.mp4'],
      'video/quicktime': ['.mov'],
      'video/x-msvideo': ['.avi'],
      'video/x-flv': ['.flv'],
      'video/x-matroska': ['.mkv'],
      'video/3gpp': ['.3gp'],
      'video/webm': ['.webm']
    },
    multiple: false,
    disabled
  });

  const handleRecordFromWeb = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) return;
    e.stopPropagation();
    ignoreClick.current = true;
    modal.open(ModalsList.RECORD_VIDEO, async (file: File) => {
      if (file) {
        handleUpload(file);
      }
    });
  };

  const handleSelectVideo = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const correctDuration = await checkVideoDuration(file);
      if (correctDuration) {
        handleUpload(file);
      } else {
        alert(`Video must be less than ${VIDEO_DURATION / 60} minutes`);
      }
    }
  };

  const handleMobileRecordVideo = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    const file = e.target.files?.[0];
    if (file) {
      const correctDuration = await checkVideoDuration(file);
      if (correctDuration) {
        handleUpload(file);
      } else {
        alert(`Video must be less than ${VIDEO_DURATION / 60} minutes`);
      }
    }
  };

  const triggerFileInput = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    ignoreClick.current = true;
    if (fileRecordVideoRef.current) {
      fileRecordVideoRef.current.click();
    }
  }, []);

  const handleDropzoneClick = async () => {
    if (ignoreClick.current) {
      ignoreClick.current = false;
      return;
    }
    await pickVideos();
  };

  const pickVideos = async () => {
    selectVideoRef.current?.click();
  };

  const confirmRemoveFile = () => {
    modal.open(
      ModalsList.CONFIRM,
      flag => {
        if (flag) {
          handleRemoveFile();
        }
      },
      {
        title: 'Remove video',
        message: 'Are you sure you want to remove the video?'
      }
    );
  };

  const handleRemoveFile = () => {
    onChange(null);
  };

  return (
    <section className="drop-video">
      <label className="label">Video Presentation</label>
      {value ? (
        <FilePreview
          fileName={value.fileName}
          fileSize={value.fileSize}
          onRemove={confirmRemoveFile}
          icon={<PlayCircle size={16} />}
        />
      ) : (
        <>
          {isWebPlatform ? (
            <WebDropzone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              handleRecordFromWeb={handleRecordFromWeb}
              isLoading={isLoading}
              disabled={disabled}
              uploadProgress={uploadProgress}
            />
          ) : (
            <MobileDropzone
              handleDropzoneClick={handleDropzoneClick}
              triggerFileInput={triggerFileInput}
              fileRecordVideoRef={fileRecordVideoRef}
              selectVideoRef={selectVideoRef}
              handleMobileRecordVideo={handleMobileRecordVideo}
              handleSelectVideo={handleSelectVideo}
              isLoading={isLoading}
              disabled={disabled}
              uploadProgress={uploadProgress}
            />
          )}
        </>
      )}
    </section>
  );
};

interface DropzoneProps {
  getRootProps?: <T extends DropzoneRootProps>() => T;
  getInputProps?: <T extends DropzoneInputProps>() => T;
  handleRecordFromWeb?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleDropzoneClick?: () => Promise<void>;
  triggerFileInput?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  fileRecordVideoRef?: React.RefObject<HTMLInputElement | null>;
  selectVideoRef?: React.RefObject<HTMLInputElement | null>;
  handleMobileRecordVideo?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectVideo?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  disabled?: boolean;
  uploadProgress?: number;
}

const WebDropzone: React.FC<DropzoneProps> = ({
  getRootProps,
  getInputProps,
  handleRecordFromWeb,
  isLoading,
  disabled,
  uploadProgress
}) => (
  <div className={classNames('video-dropzone', { disabled })} {...getRootProps!()}>
    <input {...getInputProps!()} />
    <div className="drop-video-placeholder">
      {isLoading ? (
        <>
          <div className="loading-text">Uploading video...</div>
          <progress value={uploadProgress} max="100">
            {uploadProgress}%
          </progress>
        </>
      ) : (
        <>
          <Import size={24} />
          <p>Select a file or drag and drop</p>
          <div className="drop-video-divider" />
          <div className="record-video" onClick={handleRecordFromWeb}>
            <span>Record your video</span>
          </div>
        </>
      )}
    </div>
  </div>
);

const MobileDropzone: React.FC<DropzoneProps> = ({
  handleDropzoneClick,
  triggerFileInput,
  fileRecordVideoRef,
  selectVideoRef,
  handleMobileRecordVideo,
  handleSelectVideo,
  isLoading,
  disabled,
  uploadProgress
}) => (
  <div className={classNames('video-dropzone', { disabled })} onClick={handleDropzoneClick}>
    <div className="drop-video-placeholder">
      {isLoading ? (
        <>
          <div className="loading-text">Uploading video...</div>
          <progress value={uploadProgress} max="100">
            {uploadProgress}%
          </progress>
        </>
      ) : (
        <>
          <Import size={24} />
          <p>Select a file or drag and drop</p>
          <div className="drop-video-divider" />
          <input
            type="file"
            accept="video/*"
            style={{ display: 'none' }}
            ref={selectVideoRef}
            onChange={handleSelectVideo}
          />
          <input
            type="file"
            accept="video/*"
            capture="user"
            ref={fileRecordVideoRef}
            style={{ display: 'none' }}
            onChange={handleMobileRecordVideo}
          />
          <div className="record-video" onClick={triggerFileInput}>
            <span>Record your video</span>
          </div>
        </>
      )}
    </div>
  </div>
);

export default DropVideo;
