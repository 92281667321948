import { useEffect, useRef, ForwardedRef } from 'react';

export default function <T = null>(ref: ForwardedRef<unknown>) {
  const innerRef = useRef<T>(null);

  useEffect(() => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(innerRef.current);
      } else {
        ref.current = innerRef.current;
      }
    }
  });

  return innerRef;
}
