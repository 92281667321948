import { endpointsPrototype } from 'helpers/data';
import { Methods } from 'hooks/useApi';

const endpoints: any = {
  LOGIN: { endpoint: '/auth/login', method: Methods.POST },
  REGISTER: { endpoint: '/auth/register', method: Methods.POST },
  RESEND_EMAIL_FOR_VERIFICATION: { endpoint: '/auth/email/resend', method: Methods.POST },
  VERIFY: { endpoint: '/auth/email/verify', method: Methods.POST },
  RESET_PASSWORD: { endpoint: '/auth/reset_password', method: Methods.POST },
  CONFIRM_RESET_PASSWORD: { endpoint: '/auth/confirm_reset_password', method: Methods.POST },
  VERIFY_TOKEN: { endpoint: '/auth/verify_token', method: Methods.POST },
  ON_BOARDING_STATUS: { endpoint: '/onboarding', method: Methods.GET },
  ON_BOARDING_UPDATE: { endpoint: '/onboarding', method: Methods.POST },
  DOWNLOAD_PAYMENT: { endpoint: '/payments/:id/pdf', method: Methods.GET },
  NOTICES: { endpoint: '/notices', method: Methods.GET },
  VENUES: { endpoint: '/venues', method: Methods.GET },
  EDIT_PERSONAL_INFO: { endpoint: '/employees/edit', method: Methods.PUT },
  GET_EVENT_INFO: { endpoint: '/events/:id/registered', method: Methods.GET },
  GET_MY_TAXES: { endpoint: '/employees/mytaxes', method: Methods.GET },
  GET_TAXES_DOCUMENTS: { endpoint: '/employees/document/:id/pdf', method: Methods.GET },
  GET_PAYMENTS: { endpoint: '/payments/myPayments', method: Methods.GET },
  GET_PAYMENT_URL: { endpoint: '/onboarding/payment', method: Methods.GET },
  GET_TAX_WITH_HOLD_URL: { endpoint: '/onboarding/withholding', method: Methods.GET },
  VALID_STATES: { endpoint: '/states', method: Methods.GET },
  GET_EVENTS: { endpoint: '/events/employee/list', method: Methods.GET },
  EVENTS_UPDATE_SUBSCRIPTION: { endpoint: '/events/employee/subscriptions/:id', method: Methods.PUT },
  AGREE_NOTICES: { endpoint: '/notices/agree', method: Methods.POST },
  AGREED_NOTICES: { endpoint: '/notices/agreed', method: Methods.GET },
  UPDATE_MY_VENUES: { endpoint: '/venues', method: Methods.PUT },
  GET_BACKGROUND_CHECK: { endpoint: '/backgroundcheck', method: Methods.POST },
  SAVE_NOTIFICATION_TOKEN: { endpoint: '/notifications/save', method: Methods.POST },
  DELETE_NOTIFICATION_TOKEN: { endpoint: '/notifications/delete', method: Methods.POST },
  CONFIRM_ATTENDANCE: { endpoint: '/events/:id/employee/confirm', method: Methods.PUT },
  DROPOUT_EVENT: { endpoint: '/events/:id/employee/dropout', method: Methods.PUT },
  SETTINGS_NOTIFICATIONS: { endpoint: '/notifications/push_notifications', method: Methods.POST },
  EVENT_PREFERENCES: { endpoint: '/events/:id/preferences', method: Methods.GET },
  EMPLOYEES_SUBSCRIBED: { endpoint: '/events/subscribed/:eventId?paginated=:paginated', method: Methods.POST },
  EVENT_STATUS: { endpoint: '/events/:id/status', method: Methods.GET },
  AVAILABLE_LEADER_POSITIONS: { endpoint: '/events/:id/positions/lead', method: Methods.GET },
  CERTIFICATION_SIGNED_URL: { endpoint: '/employees/certification/signed_url', method: Methods.POST },
  SAVE_CERTIFICATION: { endpoint: '/employees/certification', method: Methods.POST },
  EMPLOYEES_STATS: { endpoint: '/employees/statistics', method: Methods.POST },
  GET_TRAINING_STATUS_LIST: { endpoint: '/trainings/status?flow=:flow', method: Methods.GET },
  GET_TRAINING: { endpoint: '/trainings/:id', method: Methods.GET },
  SEND_RESPONSE: { endpoint: '/trainings/response/:trainingId', method: Methods.POST },
  FILE_APPEAL: { endpoint: '/warnings/files/signed_url', method: Methods.POST },
  CREATE_APPEAL: { endpoint: '/warnings/appeals/create', method: Methods.POST },
  SAVE_EMPLOYEE_UNIFORMS: { endpoint: '/employees/uniforms', method: Methods.POST },
  GET_ACTIVE_CHATS: { endpoint: '/chat', method: Methods.GET },
  GET_SIGNED_PROFILE_IMAGE: { endpoint: '/employees/signed_profile_image', method: Methods.POST },
  GET_NOTIFICATIONS: { endpoint: '/notifications', method: Methods.GET },
  READ_NOTIFICATION: { endpoint: '/notifications/read', method: Methods.POST },
  GET_UNREAD_NOTIFICATIONS: { endpoint: '/notifications/unread', method: Methods.GET },
  GET_VENUE_BY_STATE: { endpoint: '/venues/list/:stateId', method: Methods.GET },
  GET_APPLICATION: { endpoint: '/applications', method: Methods.GET },
  UPDATE_APPLICATION: { endpoint: '/applications', method: Methods.PUT },
  GET_VIDEO_SIGNED_URL: { endpoint: '/applications/video_signed_url', method: Methods.POST },
  GET_RESUME_SIGNED_URL: { endpoint: '/applications/resume_signed_url', method: Methods.POST },
  GET_SIGNED_SIGNATURE_IMAGE: { endpoint: '/backgroundcheck/signed_signature_image', method: Methods.POST },
  GET_FAIR_CREDIT_REPORTING_ACT_FILE: {
    endpoint: '/backgroundcheck/fair_credit_reporting_act_file',
    method: Methods.GET
  },
  GET_I9: { endpoint: '/onboarding/get-i9', method: Methods.GET },
  RESET_I9: { endpoint: '/onboarding/reset-i9', method: Methods.POST }
};

Object.assign(endpoints, endpointsPrototype);
endpoints.init();

export default endpoints;
